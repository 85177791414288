<template>
  <div v-click-outside="closeLanguageSwitcher" class="language-switcher">
    <button @click="openLanguageSwitcher()">
      <img alt="" src="@/assets/img/Taal.svg"/>
      <span>{{ activeLocalesFront[$i18n.locale] }}</span>
    </button>
    <ul class="dropdown" :class="{'active': menuOpen}">
      <li :class="{ 'active' : locale === $i18n.locale }" v-for="(localeString, locale) in activeLocalesFront" :key="locale" @click="switchLocale(locale)">
        <span class="circle">{{ locale === $i18n.locale ? '&#10003;' : '' }}</span>
        <span>{{ localeString }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data () {
    return {
      menuOpen: false
    }
  },
  methods: {
    switchLocale (locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale

        const to = this.$router.resolve({ params: { locale } })
        this.$router.push(to.location)
      }
    },
    openLanguageSwitcher () {
      this.menuOpen = true
    },
    closeLanguageSwitcher () {
      this.menuOpen = false
    }
  }
}
</script>
