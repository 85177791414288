<template>
  <div>
    <Header/>

    <main class="content">
      <transition name="fade" mode="out-in">
        <router-view :key="$route.fullPath"></router-view>
      </transition>
    </main>

    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/world/Header'
import Footer from '@/components/world/Footer'

export default {
  name: 'Layout',
  components: {
    Header,
    Footer
  },
  mounted () {
    document.body.classList.remove('admin')
    document.body.classList.add('ui')
    document.title = 'Wilms World'
  }
}
</script>
