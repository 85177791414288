<template>
  <footer>
    <div class="container">
      <div class="flex">
        <div class="logo">
          <img alt="Logo" height="94" src="@/assets/img/logo-wilms-2.svg" width="89">
        </div>

        <div class="info">
          <h2>Wilms NV</h2>
          <p>Molsebaan 20</p>
          <p>B-2450 Meerhout</p>
        </div>

        <div class="info info-contact">
          <h2>{{ $t('Contacteer ons') }}</h2>
          <p>+32(0)14 36 99 70</p>
          <p>info@wilms.be</p>
        </div>

        <div class="info">
          <h2>{{ $t('Volg ons') }}</h2>

          <div class="socials">
            <a href="https://www.facebook.com/Wilmsnv/" target="_blank" title="Facebook">
              <Icon color="white" icon="brandico:facebook"/>
            </a>
            <a href="https://www.linkedin.com/company/wilms-nv/" target="_blank" title="LinkedIn">
              <Icon color="white" icon="brandico:linkedin"/>
            </a>
            <a href="https://nl.pinterest.com/wilmsnv/" target="_blank" title="Pinterest">
              <Icon color="white" icon="akar-icons:pinterest-fill"/>
            </a>
            <a href="https://www.youtube.com/channel/UCTuOTgyCrUMJdfFAb7BJ7-w" target="_blank" title="Youtube">
              <Icon color="white" icon="akar-icons:youtube-fill"/>
            </a>
          </div>
        </div>

        <div class="stamp">
          <img alt="Patended Technology - Wilms" height="95" src="@/assets/img/stempel-wilms.svg" width="95">
        </div>

        <div class="sig">
          <img v-if="$route.params.locale == 'nl'" alt="Signature" width="234" src="@/assets/img/logo-nl.svg" height="16">
          <img v-if="$route.params.locale == 'fr'" alt="Signature" width="234" src="@/assets/img/logo-fr.svg" height="16">
          <img v-if="$route.params.locale == 'en'" alt="Signature" width="234" src="@/assets/img/logo-en.svg" height="16">
          <img v-if="$route.params.locale == 'sv'" alt="Signature" width="234" src="@/assets/img/logo-sv.svg" height="16">
        </div>

        <div class="links">
          <p>© Wilms</p>
          <a href="https://www.wilms.be/nl/ons-werkgebied">{{ $t('Ons werkgebied') }}</a>
          <a href="https://wilms-web.ams3.cdn.digitaloceanspaces.com/production/assets/main/PrivacyVerklaringNL.pdf">{{ $t('Privacyverklaring') }}</a>
          <a v-if="$route.params.locale == 'nl'" href="https://wilms-web.ams3.cdn.digitaloceanspaces.com/production/assets/main/WILMS_cookiepolicy_202101NL.pdf">{{ $t('Cookie policy') }}</a>
          <a v-if="$route.params.locale == 'sv'" href="https://wilms-web.ams3.cdn.digitaloceanspaces.com/production/assets/main/WILMS_cookiepolicy_202101SV.pdf">{{ $t('Cookie policy') }}</a>
          <a>BE 0422.115.690</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { Icon } from '@iconify/vue2'

export default {
  components: {
    Icon
  }
}
</script>
