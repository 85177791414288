<template>
  <header>
    <div class="container">
      <div class="flex justify-between">
        <div class="logo">
          <router-link :to="{ name: 'ww.dashboard' }">
            <img src="@/assets/img/logo-full.svg" alt="Logo">
          </router-link>
        </div>

        <div class="right">
          <LanguageSwitcher/>
          <div class="spacer" v-if="currentUser"></div>
          <div class="menu" v-if="currentUser">
            <div class="button" v-click-outside="closeUserMenu" @click="openUserMenu">
              <img width="38" alt="User avatar" src="~@/assets/img/user.svg"/>
              <span class="name">{{ currentUser.profile.first_name ? currentUser.profile.first_name : currentUser.email }}</span>
            </div>

            <div class="dropdown" :class="{'active': menuOpen}">
              <router-link v-if="checkPermissions(['user.accessAdminPanel'])" :to="{ name: 'admin.dashboard' }">{{ $t('Administratie') }}</router-link>
              <router-link :to="{ name: 'ww.profile' }">{{ $t('Profiel') }}</router-link>
              <router-link :to="{ name: 'ww.logout' }">{{ $t('Afmelden') }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import LanguageSwitcher from '@/components/world/LanguageSwitcher'

export default {
  components: {
    LanguageSwitcher
  },
  data () {
    return {
      menuOpen: false
    }
  },
  methods: {
    openUserMenu () {
      this.menuOpen = true
    },
    closeUserMenu () {
      this.menuOpen = false
    }
  }
}
</script>
